import React, { useState } from "react";
import "./style.scss";
import { base64Encode } from "../../env";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../redux-store/slices/user-details";
import {
  getUserFromDataBase,
  updateUserOnDataBase,
} from "../../services/user-services";
import { useNavigate } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../services/firebase-connection";
import { Box, Modal } from "@mui/material";
import ReactLoading from "react-loading";
import { upload } from "../../services/firebase-services";
import { onAuthStateChanged } from "firebase/auth";

const UserSettingInfo = () => {
  const stripe = useStripe();
  const elements = useElements();
  let userDetails = useSelector((store) => store.user.userDetails);
  let dispatch = useDispatch();
  const navigate = useNavigate();

  function useAuth() {
    const [currentUser, setCurrentUser] = useState();

    useEffect(() => {
      const unsub = onAuthStateChanged(auth, (user) => setCurrentUser(user));
      return unsub;
    }, []);

    return currentUser;
  }
  const [userInfoDetails, setUserInfoDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    profile_picture: "",
    billing_address_line_1: "",
    billing_address_line_2: "",
    billing_pin: "",
    billing_country: "",
    shipping_address_equals_billing_address: false,
    shipping_address_line_1: "",
    shipping_address_line_2: "",
    shipping_pin: "",
    shipping_country: "",
    name_on_card: "",
    card_country: "",
    stripe_customer_id: "",
    stripe_mandate_id: "",
    stripe_payment_method_id: "",
    terms_and_condition: false,
  });

  const [userDetailLoader, setUserDetailLoader] = useState(false);
  const [updatePaymentCardEnable, setUpdatePaymentCardEnable] = useState(false);
  const [paymentDetailsCreated, setPaymentDetailsCreated] = useState(false);
  useEffect(() => {
    (async () => {
      setUserDetailLoader(true);
      let userDetailsFromStore = localStorage.getItem("userDetails");
      console.log(userDetailsFromStore, "user-->", userDetails);
      if (userDetails) {
        console.log(userDetails, "userDetailsuserDetails");
        //  let uid = JSON.parse(userDetailsFromStore).uid;
        let docRef = doc(db, "users", userDetails?.uid);
        let documentSnapshot = await getDoc(docRef);
        console.log(documentSnapshot.exists(), "lllllll");
        if (documentSnapshot.exists()) {
          console.log(documentSnapshot.data());
          let data = documentSnapshot.data();
          let name =
            (userDetails.displayName && userDetails?.displayName?.split(" ")) ||
            "";
          console.log(data, "p0ooooo");
          setUserInfoDetails((prevState) => ({
            ...prevState,
            first_name: name[0] ? name[0] : "",
            last_name: name[1] ? name[1] : "",
            email: data.email ? data.email : "",
            contact_number: data.contact_number ? data.contact_number : "",
            profile_picture: data.profile_picture ? data.profile_picture : "",
            billing_address_line_1: data.billing_address_line_1
              ? data.billing_address_line_1
              : "",
            billing_address_line_2: data.billing_address_line_2
              ? data.billing_address_line_2
              : "",
            billing_pin: data.billing_pin ? data.billing_pin : "",
            billing_country: data.billing_country ? data.billing_country : "",
            shipping_address_equals_billing_address:
              data.shipping_address_equals_billing_address
                ? data.shipping_address_equals_billing_address
                : "",
            shipping_address_line_1: data.shipping_address_line_1
              ? data.shipping_address_line_1
              : "",
            shipping_address_line_2: data.shipping_address_line_2
              ? data.shipping_address_line_2
              : "",
            shipping_pin: data.shipping_pin ? data.shipping_pin : "",
            shipping_country: data.shipping_country
              ? data.shipping_country
              : "",
            name_on_card: data.name_on_card ? data.name_on_card : "",
            card_country: data.card_country ? data.card_country : "",
            terms_and_condition: data.terms_and_condition
              ? data.terms_and_condition
              : "",
            stripe_customer_id: data.stripe_customer_id
              ? data.stripe_customer_id
              : "",
            stripe_mandate_id: data.stripe_mandate_id
              ? data.stripe_mandate_id
              : "",
            stripe_payment_method_id: data.stripe_payment_method_id
              ? data.stripe_payment_method_id
              : "",
          }));
          data.stripe_payment_method_id && setUpdatePaymentCardEnable(true);
          data.stripe_payment_method_id
            ? setPaymentDetailsCreated(true)
            : setPaymentDetailsCreated(false);
        }
        dispatch(setUserDetails(JSON.parse(userDetailsFromStore)));
      }
      setUserDetailLoader(false);
    })();
  }, []);

  const handleUserInfoFormData = (e) => {
    let { name, value } = e.target;
    // console.log({ name, value });
    if (name === "shipping_address_equals_billing_address") {
      setUserInfoDetails((prevState) => {
        if (prevState.shipping_address_equals_billing_address) {
          return {
            ...prevState,
            [name]: false,
            ["shipping_address_line_1"]: "",
            ["shipping_address_line_2"]: "",
            ["shipping_country"]: "",
            ["shipping_pin"]: "",
          };
        } else {
          return {
            ...prevState,
            [name]: true,
            ["shipping_address_line_1"]: prevState.billing_address_line_1,
            ["shipping_address_line_2"]: prevState.billing_address_line_2,
            ["shipping_country"]: prevState.billing_country,
            ["shipping_pin"]: prevState.billing_pin,
          };
        }
      });
    } else if (name === "terms_and_condition") {
      setUserInfoDetails((prevState) => ({
        ...prevState,
        [name]: !prevState.terms_and_condition,
      }));
    } else {
      setUserInfoDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  let streamDetails = useSelector((store) => store.liveStreamData);
  // console.log("streamDetails at user setting info", streamDetails);

  const [updateDetailsLoader, setUpdateDetailsLoader] = useState(false);
  const handleUserInfoFormDetailSubmission = async (e) => {
    e.preventDefault();
    setUpdateDetailsLoader(true);
    console.log("form details", userInfoDetails);
    if (!stripe || !elements) {
      setUpdateDetailsLoader(false);
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      if (!paymentDetailsCreated && !updatePaymentCardEnable) {
        console.log("cardElement", cardElement);
        let stripeCustomerObject = {
          address: {
            city: userInfoDetails.billing_address_line_1,
            country: userInfoDetails.billing_country,
            line1: userInfoDetails.billing_address_line_1,
            line2: userInfoDetails.billing_address_line_2,
            postal_code: userInfoDetails.billing_pin,
            state: userInfoDetails.billing_address_line_2,
          },
          description: "",
          email: userInfoDetails.email,
          metadata: {},
          name: `${userInfoDetails.first_name} ${userInfoDetails.last_name}`,
          phone: userInfoDetails.contact_number,
          shipping: {
            address: {
              city: userInfoDetails.billing_address_line_1,
              country: userInfoDetails.billing_country,
              line1: userInfoDetails.billing_address_line_1,
              line2: userInfoDetails.billing_address_line_2,
              postal_code: userInfoDetails.billing_pin,
              state: userInfoDetails.billing_address_line_2,
            },
            name: `${userInfoDetails.first_name} ${userInfoDetails.last_name}`,
            phone: userInfoDetails.contact_number,
          },
        };
        console.log(stripeCustomerObject);
        const options_ = {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Specify content type
          },
          body: JSON.stringify(stripeCustomerObject), // Convert data to JSON string
        };
        let createCustomerResponse = await fetch(
          "https://us-central1-live-auction-app-ff9b8.cloudfunctions.net/createCustomer",
          options_
        );
        if (!createCustomerResponse.ok) {
          throw new Error("Network response was not ok");
        }
        createCustomerResponse = await createCustomerResponse.json();
        console.log("createCustomerResponse", createCustomerResponse);
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });
        console.log("payment method", paymentMethod, error);
        if (error) {
          console.log("error from stripe payment method creation", error);
          setUpdateDetailsLoader(false);
          return;
        }
        let { id } = paymentMethod;
        let { id: customerId } = createCustomerResponse.data;
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Specify content type
          },
          body: JSON.stringify({ id, customerId }), // Convert data to JSON string
        };
        let mandateInfoUpdateResponse = await fetch(
          "https://us-central1-live-auction-app-ff9b8.cloudfunctions.net/createMandate",
          options
        );
        if (!mandateInfoUpdateResponse.ok) {
          throw new Error("Network response was not ok");
        }

        mandateInfoUpdateResponse = await mandateInfoUpdateResponse.json();
        console.log("mandateInfoUpdateResponse", mandateInfoUpdateResponse);
        let { id: mandateId } = mandateInfoUpdateResponse.setupIntent;
        userInfoDetails.stripe_customer_id = customerId;
        userInfoDetails.stripe_payment_method_id = id;
        userInfoDetails.stripe_mandate_id = mandateId;
      } else if (paymentDetailsCreated && !updatePaymentCardEnable) {
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });
        console.log("updated payment method", paymentMethod, error);
        if (error) {
          console.log("error from stripe payment method creation", error);
          setUpdateDetailsLoader(false);
          return;
        }
        let { id } = paymentMethod;
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Specify content type
          },
          body: JSON.stringify({
            setupIntentId: userInfoDetails.stripe_mandate_id,
            customerId: userInfoDetails.stripe_customer_id,
            paymentMethodId: id,
          }), // Convert data to JSON string
        };
        let mandateInfoUpdateResponse = await fetch(
          "https://us-central1-live-auction-app-ff9b8.cloudfunctions.net/updateMandate",
          options
        );
        if (!mandateInfoUpdateResponse.ok) {
          throw new Error("Network response was not ok");
        }
        mandateInfoUpdateResponse = await mandateInfoUpdateResponse.json();
        console.log(
          "updated mandateInfoUpdateResponse",
          mandateInfoUpdateResponse
        );
        let { id: mandateId } = mandateInfoUpdateResponse.updateSetupIntent;
        userInfoDetails.stripe_payment_method_id = id;
        userInfoDetails.stripe_mandate_id = mandateId;
      }
      console.log("user details", userInfoDetails, userDetails);
      await updateUserOnDataBase(userDetails.uid, userInfoDetails);
      let dataFromDb = await getUserFromDataBase(userDetails.uid);
      console.log("data from db", dataFromDb);
      const {
        accessToken,
        email,
        displayName,
        emailVerified,
        phoneNumber,
        photoURL,
        uid,
        avatar,
        name_on_card,
        profile_picture,
      } = dataFromDb;
      console.log("data from firestore", dataFromDb);
      localStorage.setItem(
        "userDetails",
        JSON.stringify({
          accessToken,
          email,
          avatar,
          displayName,
          emailVerified,
          phoneNumber,
          photoURL,
          uid,
          name_on_card,
          profile_picture,
        })
      );
      dispatch(setUserDetails(dataFromDb));
      if (location.search.includes("?redirect=")) {
        const redirectUrl = location.search.replace("?redirect=", "");
        // console.log("redirectUrl",redirectUrl);
        navigate(redirectUrl);
      } else {
        navigate(
          `/live-stream?uid=${streamDetails.stream_owner_uid}&streamId=${streamDetails.stream_uid}`
        );
      }
    } catch (err) {
      console.log("error encountered", err);
    }
    setUpdateDetailsLoader(false);
  };
  const style = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 800,
    // bgcolor: "background.paper",
    // border: "2px solid #000",
    // boxShadow: 24,
    border: "none",
    outline: "none",
    p: 4,
  };

  const [photo, setPhoto] = useState(null);

  const currentUser = useAuth();

  const handleProfilePicChange = async (e) => {
    e.preventDefault();
    // Check if the file is an image
    let file = e.target.files[0];

    if (!file.type.startsWith("image/")) {
      alert("Please select an image file.");
      return;
    }

    if (file.size > 1024 * 1024) {
      alert("The file size should not exceed 5MB.");
      return;
    }

    if (e.target.files[0]) {
      setPhoto(e.target.files[0]);
    }

    const imageURL = await upload(file, currentUser, setImageLoading);
    console.log("imageURL: ", imageURL);
    setPhotoURL(imageURL);
    setUserInfoDetails((prevDetails) => ({
      ...prevDetails,
      profile_picture: imageURL,
    }));
  };

  const [imageLoading, setImageLoading] = useState(false);
  const [photoURL, setPhotoURL] = useState("");

  return (
    <div className="payment_section">
      <Modal open={userDetailLoader} onClose={setUserDetailLoader}>
        <Box sx={style}>
          <ReactLoading type={"spin"} color="black" height={35} width={35} />
        </Box>
      </Modal>
      <form onSubmit={handleUserInfoFormDetailSubmission}>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7 paymentleft_container">
            <div className="paymentleft_card">
              <div className="paymentcard_header">
                <p className="payment_heading">Customer Details</p>
              </div>
              <div className="paymentcard_body">
                <div className="userinfo_box">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 paymentleft-input-sections">
                      <div className="payment_group">
                        <p className="pay_label">First Name</p>
                        <input
                          type="text"
                          className="form-control payment_form_control"
                          placeholder="Enter your First Name"
                          name="first_name"
                          onChange={handleUserInfoFormData}
                          value={userInfoDetails.first_name}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 paymentleft-input-sections">
                      <div className="payment_group">
                        <p className="pay_label">Last Name</p>
                        <input
                          type="text"
                          className="form-control payment_form_control"
                          placeholder="Enter your last name"
                          name="last_name"
                          onChange={handleUserInfoFormData}
                          value={userInfoDetails.last_name}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 paymentleft-input-sections">
                      <div className="payment_group">
                        <p className="pay_label">Email ID</p>
                        <input
                          type="text"
                          className="form-control payment_form_control"
                          placeholder="Enter your email ID"
                          name="email"
                          disabled
                          onChange={handleUserInfoFormData}
                          value={userInfoDetails.email}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 paymentleft-input-sections">
                      <div className="payment_group">
                        <p className="pay_label">Contact Number</p>
                        <input
                          type="text"
                          className="form-control payment_form_control"
                          placeholder="Enter your Contact Number"
                          name="contact_number"
                          onChange={handleUserInfoFormData}
                          value={userInfoDetails.contact_number}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 paymentleft-input-sections">
                      <div className="payment_group">
                        <p className="pay_label">Profile Picture</p>
                        <input
                          type="file"
                          accept="image/jpeg, image/png"
                          className="form-control payment_form_control"
                          placeholder="Upload File"
                          name="profile_picture"
                          onChange={handleProfilePicChange}
                        />
                        <br />
                        <br />
                        {userInfoDetails.profile_picture && (
                          <img
                            src={userInfoDetails.profile_picture}
                            alt="profile"
                            style={{height:100, width:100}}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <p
                      className="user_info_heading"
                      style={{ marginTop: "8px" }}
                    >
                      Billing Address
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 paymentleft-input-sections">
                    <div className="payment_group">
                      <p className="pay_label">Home / Street /Land mark</p>
                      <input
                        type="text"
                        className="form-control payment_form_control"
                        placeholder="Address Line 1"
                        name="billing_address_line_1"
                        onChange={handleUserInfoFormData}
                        value={userInfoDetails.billing_address_line_1}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 paymentleft-input-sections">
                    <div className="payment_group">
                      <p className="pay_label">City / Town </p>
                      <input
                        type="text"
                        className="form-control payment_form_control"
                        placeholder="Address Line 2"
                        name="billing_address_line_2"
                        onChange={handleUserInfoFormData}
                        value={userInfoDetails.billing_address_line_2}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 paymentleft-input-sections">
                    <div className="payment_group">
                      <p className="pay_label">Zip Code</p>
                      <input
                        type="text"
                        className="form-control payment_form_control"
                        placeholder="Zip"
                        name="billing_pin"
                        onChange={handleUserInfoFormData}
                        value={userInfoDetails.billing_pin}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 paymentleft-input-sections">
                    <div className="payment_group">
                      <p className="pay_label">Country</p>
                      <input
                        type="text"
                        className="form-control payment_form_control"
                        placeholder="Country"
                        name="billing_country"
                        onChange={handleUserInfoFormData}
                        value={userInfoDetails.billing_country}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <p
                      className="user_info_heading"
                      style={{ marginTop: "8px" }}
                    >
                      Shipping Information
                    </p>
                  </div>
                  <div className="form-check payment_from_check mb-0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="shipping_address_equals_billing_address"
                      value={
                        userInfoDetails.shipping_address_equals_billing_address
                      }
                      onChange={handleUserInfoFormData}
                      checked={
                        userInfoDetails.shipping_address_equals_billing_address
                      }
                    />
                    <label className="form-check-label">
                      Shipping information is same as billling address
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 paymentleft-input-sections">
                    <div className="payment_group">
                      <p className="pay_label">Home / Street /Land mark</p>
                      <input
                        disabled={
                          userInfoDetails.shipping_address_equals_billing_address
                        }
                        type="text"
                        className="form-control payment_form_control"
                        placeholder="Address Line 1"
                        name="shipping_address_line_1"
                        onChange={handleUserInfoFormData}
                        value={userInfoDetails.shipping_address_line_1}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 paymentleft-input-sections">
                    <div className="payment_group">
                      <p className="pay_label">City / Town </p>
                      <input
                        disabled={
                          userInfoDetails.shipping_address_equals_billing_address
                        }
                        type="text"
                        className="form-control payment_form_control"
                        placeholder="Address Line 2"
                        name="shipping_address_line_2"
                        onChange={handleUserInfoFormData}
                        value={userInfoDetails.shipping_address_line_2}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 paymentleft-input-sections">
                    <div className="payment_group">
                      <p className="pay_label">Zip Code</p>
                      <input
                        disabled={
                          userInfoDetails.shipping_address_equals_billing_address
                        }
                        type="text"
                        className="form-control payment_form_control"
                        placeholder="Zip Code"
                        name="shipping_pin"
                        onChange={handleUserInfoFormData}
                        value={userInfoDetails.shipping_pin}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 paymentleft-input-sections">
                    <div className="payment_group">
                      <p className="pay_label">Country</p>
                      <input
                        disabled={
                          userInfoDetails.shipping_address_equals_billing_address
                        }
                        type="text"
                        className="form-control payment_form_control"
                        placeholder="Country"
                        name="shipping_country"
                        onChange={handleUserInfoFormData}
                        value={userInfoDetails.shipping_country}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5 paymentright_container">
            <div className="paymentright_card">
              <div className="paymentcard_body">
                <div className="checkout_box" />
                <div className="row">
                  <div className="col-sm-12">
                    <p
                      className="user_info_heading"
                      style={{ marginTop: "8px" }}
                    >
                      Checkout Information
                    </p>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="payment_group">
                      <p className="pay_label">Name on Card</p>
                      <input
                        type="text"
                        className="form-control payment_form_control"
                        placeholder="Name on Card"
                        name="name_on_card"
                        onChange={handleUserInfoFormData}
                        value={userInfoDetails.name_on_card}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="payment_group">
                      <p className="pay_label">Country</p>
                      <input
                        type="text"
                        className="form-control payment_form_control"
                        placeholder="Country"
                        name="card_country"
                        onChange={handleUserInfoFormData}
                        value={userInfoDetails.card_country}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="payment_group">
                      <p className="pay_label">Card Number</p>
                      {console.log(
                        "update state",
                        paymentDetailsCreated,
                        updatePaymentCardEnable
                      )}
                      {!paymentDetailsCreated ? (
                        <CardElement
                          options={{
                            style: {
                              base: {
                                fontSize: "16px",
                                color: "#424770",
                                "::placeholder": {
                                  color: "#aab7c4",
                                },
                              },
                            },
                          }}
                        />
                      ) : updatePaymentCardEnable ? (
                        <p style={{ textAlign: "center" }}>
                          **Card is already added,{" "}
                          <button
                            style={{
                              textDecoration: "underline",
                              color: "blue",
                            }}
                            onClick={() => setUpdatePaymentCardEnable(false)}
                          >
                            Click here
                          </button>{" "}
                          if you want to update it.
                        </p>
                      ) : (
                        <>
                          <CardElement
                            options={{
                              style: {
                                base: {
                                  fontSize: "16px",
                                  color: "#424770",
                                  "::placeholder": {
                                    color: "#aab7c4",
                                  },
                                },
                              },
                            }}
                          />
                          <p style={{ textAlign: "center" }}>
                            **Don't want to update?
                            <button
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              onClick={() => setUpdatePaymentCardEnable(true)}
                            >
                              Click here
                            </button>{" "}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-check payment_from_check">
                  <input
                    className="form-check-input rounded-0"
                    type="checkbox"
                    name="terms_and_condition"
                    checked={userInfoDetails.terms_and_condition}
                    value={userInfoDetails.terms_and_condition}
                    onChange={handleUserInfoFormData}
                    required
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    I accept e-mandate on my card and other privacy policy.
                  </label>
                </div>
                <button
                  className="btn place_order_btn"
                  type="submit"
                  style={{
                    width: "200px",
                    display: "flex",
                    justifyContent: "center",
                    height: "50px",
                    alignItems: "center",
                  }}
                  disabled={updateDetailsLoader}
                >
                  {updateDetailsLoader ? (
                    <ReactLoading
                      type={"spin"}
                      color="black"
                      height={25}
                      width={25}
                    />
                  ) : (
                    "Update Details"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserSettingInfo;
