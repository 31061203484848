import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../services/firebase-connection';
import './style.scss';
import {
  createUserOnDataBase,
  getUserFromDataBase,
} from '../../services/user-services';
import LoadingScreen from '../../components/loader';
import { InfoComponent } from '../../components/info-component';
import { redirectToLogin, redirectToSignup } from '../../services/helpers';
import toast, { Toaster } from 'react-hot-toast';
import { setUserDetails } from '../../redux-store/slices/user-details';
import { useDispatch } from 'react-redux';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import ReactLoading from 'react-loading';
import { Box } from '@mui/material';
const Signup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [signUpLoader, setSignupLoader] = useState(false);
  const url = location.pathname;
  const searchParams = location.search;
  const dispatch = useDispatch();
  // console.log(url, searchParams);

  let queryParams = JSON.parse(localStorage.getItem('queryParams'));

  const {streamId, uid} = queryParams;

  let streamUrl = `?uid=${uid}&streamId=${streamId}`;
  console.log('signup', 'queryParams: ', queryParams);

  const showToaster = ({ info, id, duration, icon }) => {
    console.log(info, id, duration, icon);
    toast(
      (t) => {
        return <span className='text-black/50'>{info ? info : ''}</span>;
      },
      {
        id: id ? id : '',
        duration: duration ? duration : 4000,
        icon: icon ? icon : '',
      }
    );
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setSignupLoader(true);
    console.log(email, password, confirmPassword);
    if (password !== confirmPassword) {
      setSignupLoader(false);
      return toast.error('Password did not match please try again');
    }
    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        let user = userCredential.user;
        user.displayName = fullName;
        console.log(user);
        const createUserResponse = await createUserOnDataBase(user);
        console.log('createUserResponse', createUserResponse);
        // let dataFromDb = await getUserFromDataBase(user.uid);
        // console.log("updateUserResponse", dataFromDb);
        const {
          accessToken,
          email,
          displayName,
          emailVerified,
          phoneNumber,
          photoURL,
          uid,
          avatar,
        } = user;
        localStorage.setItem(
          'userDetails',
          JSON.stringify({
            accessToken,
            email,
            avatar,
            displayName,
            emailVerified,
            phoneNumber,
            photoURL,
            uid,
          })
        );
        dispatch(setUserDetails(user));
        setSignupLoader(false);
        navigate("/setting");
        // navigate(`/live-stream${streamUrl}`);
      })
      .catch((error) => {
        setSignupLoader(false);
        console.log('Error: ', error);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        showToaster({
          info: errorMessage,
          id: 'email_error',
          duration: 3000,
          icon: '⚠️',
        });
      });
  };

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <div className='login_content'>
      <Toaster
        toastOptions={{
          duration: 5000,
          style: {
            marginTop: '100px',
          },
        }}
      />
      <div className='login_box'>
        <form onSubmit={onFormSubmit}>
          <div className='login_box_inner'>
            <p className='welcome_text_login'>
              <span>Create your account</span>
            </p>
            <div className='login_control mb_24'>
              <p className='login_password_label'>Full name</p>
              <input
                type='text'
                className='form-control login_form_control'
                placeholder='Enter full name'
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </div>
            <div className='login_control mb_24'>
              <p className='login_password_label'>Email</p>
              <input
                type='email'
                className='form-control login_form_control'
                placeholder='Enter your primary email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className='login_control mb_24'>
              <p className='login_password_label'>Enter password</p>
              <input
                type='password'
                className='form-control login_form_control'
                placeholder='Enter password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <i
                className='fa fa-eye-slash password_eye_login'
                aria-hidden='true'
              ></i>
              <i
                className='fa fa-eye password_eye_login'
                aria-hidden='true'
              ></i>
            </div>
            <div className='login_control mb_75'>
              <p className='login_password_label'>Confirm password</p>
              <input
                type='password'
                className='form-control login_form_control'
                placeholder='Confirm password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <i
                className='fa fa-eye-slash password_eye_login'
                aria-hidden='true'
              ></i>
              <i
                className='fa fa-eye password_eye_login'
                aria-hidden='true'
              ></i>
            </div>
            <button type='submit' className='btn continue_login_btn'>
              {signUpLoader ? (
                <Box
                  sx={{
                    // width: "10px",
                    // marginTop: "100px",
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <ReactLoading
                    type={'spin'}
                    color='black'
                    height={25}
                    width={25}
                  />
                </Box>
              ) : (
                'Sign Up'
              )}
            </button>
            <p className='have_account'>
              Already have an account?{' '}
              <button
                href=''
                type=''
                onClick={handleLoginRedirect}
                className='have_account_btn'
                style={{ color: 'blue' }}
              >
                Login
              </button>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
