import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Signup from "./views/sign-up";
import Login from "./views/log-in";
import { Provider } from "react-redux";
import { persistor, store } from "./redux-store/store";
import "./App.scss";
import { ShopifyAuction } from "./views/shopify-auction";
import { Header } from "./components/header";
import { Settings } from "./views/settings";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Winnings from "./views/winnings";
import { PersistGate } from "redux-persist/integration/react";

const App = () => {
  const stripePromise = loadStripe("pk_test_jBGtc7Lq3LGHISsBbkW6897z");
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Elements stripe={stripePromise}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={<Navigate replace to="/live-stream" />}
              />
              <Route element={<Header />}>
                <Route path="/live-stream" element={<ShopifyAuction />} />
                <Route path="/setting" element={<Settings />} />
                <Route path="/winnings" element={<Winnings />} />
              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
            </Routes>
          </BrowserRouter>
        </Elements>
      </PersistGate>
    </Provider>
  );
};

export default App;
