import React, { useEffect, useState } from "react";
import "./style.scss";
import profile_image from "../../assets/images/profile-image-2.jpeg";
import { useSelector } from "react-redux";
import { getInitials, redirectToLogin } from "../../services/helpers";
import { updateLiveBidderAction } from "../../services/firebase-services";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import queryString from "query-string";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../services/firebase-connection";

export const StreamLeftBar = ({timeInSeconds}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [check, setCheck] = useState(true);
  const location = useLocation();
  let userDetails = useSelector((store) => store.user.userDetails);
  let streamDetails = useSelector((store) => store.liveStreamData);
  let bids = useSelector((store) => store.liveStreamData.bids);
  let like_reacts = useSelector((store) => store.liveStreamData.like_reacts);
  let love_reacts = useSelector((store) => store.liveStreamData.love_reacts);
  let [sellerDetails, setSellerDetails] = useState({});
  useEffect(() => {
    (async () => {
      const queryParams = queryString.parse(location.search);
      // Now you can access the query parameters like this
      const uid = queryParams.uid; // This will be "0"
      const streamId = queryParams.streamId; // This will be "0"
      console.log("uid", uid, "streamId", streamId);
      if (uid && streamId) {
        let docRef = doc(db, "users", uid);
        let docData = await getDoc(docRef);
        console.log("function is here");
        if (docData.exists()) {
          console.log("function is not here");
          let data = docData.data();
          setSellerDetails(data);
        }
      }
      if (uid == uid && streamId == 0) {
        setCheck(false);
      }
    })();
    console.log(check, "--------dd");
  }, []);

  // let live_bidders = useSelector((store) => store.liveStreamData.live_bidders);
  return (
    <div className="inner_list left_content_body">
      <div className="left_content_body_inner">
        {timeInSeconds !== 0 && (
          <>
            <div className="left_header">
              <p className="monstera_text">
                {streamDetails.stream_details.stream_title ? (
                  streamDetails.stream_details.stream_title
                ) : check ? (
                  <ReactLoading
                    type={"spin"}
                    color="black"
                    height={25}
                    width={25}
                  />
                ) : null}
              </p>
              <p className="share_text">Share</p>
            </div>

            <div className="conent_text_body">
              <p className="plants_text">
                {streamDetails.stream_details?.stream_description ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: streamDetails.stream_details.stream_description,
                    }}
                  ></span>
                ) : check ? (
                  <ReactLoading
                    type={"spin"}
                    color="black"
                    height={25}
                    width={25}
                  />
                ) : null}
              </p>
              {streamDetails?.stream_details?.title && (
                <p>
                  You can buy this product{" "}
                  <a
                    href={`https://${
                      sellerDetails.shop
                    }/products/${streamDetails?.stream_details?.title
                      ?.replace(/\s+/g, "-")
                      .toLowerCase()}`}
                    target="_blank"
                    style={{
                      textDecoration: "underline",
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    here
                  </a>
                </p>
              )}
            </div>
            <div className="bid_box">
              <div className="bid_box_inner">
                <div className="like_box">
                  <button
                    className="like_link"
                    onClick={() => {
                      if (!userDetails.email) {
                        // const url = location.pathname;
                        // const searchParams = location.search;
                        // redirectToLogin("login", url, searchParams);
                        navigate("/login")
                      }
                      if (
                        like_reacts &&
                        like_reacts.filter((f) => f.uid == userDetails.uid)
                          .length > 0
                      ) {
                        updateLiveBidderAction(
                          "dislike_react",
                          userDetails,
                          streamDetails
                        );
                      } else {
                        updateLiveBidderAction(
                          "like_react",
                          userDetails,
                          streamDetails
                        );
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill={
                        like_reacts &&
                        like_reacts.filter((f) => f.uid == userDetails.uid)
                          .length > 0
                          ? "#007BFF"
                          : "none"
                      }
                    >
                      <path
                        d="M6.30176 17.6023L9.5244 20.0972C9.94022 20.513 10.8758 20.721 11.4996 20.721H15.4499C16.6974 20.721 18.0488 19.7854 18.3607 18.5379L20.8556 10.9491C21.3754 9.49369 20.4398 8.24622 18.8805 8.24622H14.7222C14.0985 8.24622 13.5787 7.72644 13.6826 6.99874L14.2024 3.67214C14.4103 2.73654 13.7866 1.69698 12.851 1.38511C12.0193 1.07324 10.9798 1.48906 10.564 2.1128L6.30176 8.45413"
                        stroke={
                          like_reacts &&
                          like_reacts.filter((f) => f.uid == userDetails.uid)
                            .length > 0
                            ? "none"
                            : "#89879E"
                        }
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M1 17.6023V7.41456C1 5.95917 1.62374 5.43939 3.07912 5.43939H4.11869C5.57407 5.43939 6.19781 5.95917 6.19781 7.41456V17.6023C6.19781 19.0577 5.57407 19.5774 4.11869 19.5774H3.07912C1.62374 19.5774 1 19.0577 1 17.6023Z"
                        stroke={
                          like_reacts &&
                          like_reacts.filter((f) => f.uid == userDetails.uid)
                            .length > 0
                            ? "none"
                            : "#89879E"
                        }
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <p className="like_count">
                    {like_reacts ? like_reacts.length : "0"}{" "}
                  </p>
                </div>
                <p className="bids_eight_text">
                  {bids && bids.length ? bids.length : "0"} Bids
                </p>
                <div className="heart_box">
                  <p className="heart_count">
                    {love_reacts && love_reacts ? love_reacts.length : "0"}
                  </p>
                  <button
                    href=""
                    className="heart_link"
                    onClick={() => {
                      if (!userDetails.email) {
                        // const url = location.pathname;
                        // const searchParams = location.search;
                        // redirectToLogin("login", url, searchParams);
                        navigate("/login")
                      }
                      if (
                        love_reacts &&
                        love_reacts.filter((f) => f.uid == userDetails.uid)
                          .length > 0
                      ) {
                        updateLiveBidderAction(
                          "undo_love_react",
                          userDetails,
                          streamDetails
                        );
                      } else {
                        updateLiveBidderAction(
                          "love_react",
                          userDetails,
                          streamDetails
                        );
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M17.328 1C15.156 1 13.212 2.08764 12 3.75618C10.788 2.08764 8.844 1 6.672 1C2.988 1 0 4.08989 0 7.90899C0 9.37978 0.228 10.7393 0.624 12C2.52 18.1798 8.364 21.8753 11.256 22.8888C11.664 23.0371 12.336 23.0371 12.744 22.8888C15.636 21.8753 21.48 18.1798 23.376 12C23.772 10.7393 24 9.37978 24 7.90899C24 4.08989 21.012 1 17.328 1Z"
                        fill={
                          love_reacts &&
                          love_reacts.filter((f) => f.uid == userDetails.uid)
                            .length > 0
                            ? "#b8003d"
                            : "#FA254C"
                        }
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="starting_bid" style={{ flexDirection: "column" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className="starting_bid_left" style={{}}>
                  Starting Bid
                </p>
                <p className="starting_bid_right">
                  $
                  {streamDetails.stream_details.starting_bid
                    ? streamDetails.stream_details.starting_bid
                    : 0}
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className="starting_bid_left">Shipping Cost</p>
                <p className="starting_bid_right">
                  ${streamDetails.stream_details.stream_bid_shipping_amount}
                </p>
              </div>
            </div>

            {/* <div className="photo_box">
          <p className="photo_text">Photos</p>
          <div className="photo_list">
            <div className="photo_list_inner">
              <div className="photo_list_inner_photo">
                <img src="./assets/images/photo-1.jpeg" alt="" />
              </div>
            </div>
            <div className="photo_list_inner">
              <div className="photo_list_inner_photo">
                <img src="./assets/images/photo-2.jpeg" alt="" />
              </div>
            </div>
            <div className="photo_list_inner">
              <div className="photo_list_inner_photo">
                <img src="./assets/images/photo-3.jpeg" alt="" />
              </div>
            </div>
            <div className="photo_list_inner">
              <div className="photo_list_inner_photo">
                <img src="./assets/images/photo-4.jpeg" alt="" />
              </div>
            </div>
          </div>
        </div> */}
            <div className="profiledrp_box">
              <div className="profiledrp_box_inner">
                <div className="profiledrp_media">
                  <div className="profile_heading_box">
                    {/* <div className="profile_icon_box">
                  {console.log("seller details", sellerDetails)}
                  {userDetails.photoURL ? (
                    <img src={userDetails.photoURL} alt={""} />
                  ) : userDetails.avatar ? (
                    <img
                      src={getAvatar(userDetails.avatar)}
                      height={35}
                      width={35}
                      alt={""}
                    />
                  ) : (
                    <span>
                      {userDetails.displayName
                        ? getInitials(userDetails.displayName)
                        : ""}
                    </span>
                  )}
                </div> */}
                    <div className="profile_heding_box">
                      <p>Seller Info</p>
                      <a
                        href="https://quickstart-59bc5295.myshopify.com/"
                        target="_blank"
                        style={{ fontWeight: 800 }}
                      >
                        {sellerDetails.shop_name ? sellerDetails.shop_name : ""}
                      </a>
                    </div>
                    {/* <a href="#" className="profile_link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      d="M1 13L7 7L0.999999 1"
                      stroke="#10170C"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a> */}
                  </div>
                  {/* <div className="profile_body_content">
                <p>
                  Hello! I am James, I love nature and bio technology. Please
                  connect to stay updated with the world of nature and bio
                  technology!
                </p>
              </div> */}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
