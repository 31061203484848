export const environment = 'development'
export const broadcastAppUrl =
  environment == 'development'
    ? 'localhost:3000'
    : 'https://stream-livesellnow.web.app'

export const hostName = 'https://api.livesellnow.com/dev/admin/api'

export const firebaseConfigure = {
  apiKey: 'AIzaSyCfigT5j_aRNphEfw8KbNAavedrVlpSE08',
  authDomain: 'live-auction-app-ff9b8.firebaseapp.com',
  projectId: 'live-auction-app-ff9b8',
  storageBucket: 'live-auction-app-ff9b8.appspot.com',
  messagingSenderId: '162421259261',
  appId: '1:162421259261:web:52b6ad54a0b1aca6da3794',
  measurementId: 'G-REKRKGDDVL'
}

export const awsConfigure = {
  accessKey: 'AKIAUE7XA73ZFRND2BP3',
  secretAccessKey: '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',
  region: 'us-east-1'
}

export const shopifyConfig = {
  SHOPIFY_API_KEY: 'ec117eb3cd1a3916231e73bade4aa94f',
  SHOPIFY_API_SECRET: '855efa083856fd65427a061c5a58daf6',
  SCOPES:
    'write_products,read_orders,write_orders,read_products,read_shipping,write_shipping,read_product_listings',
  HOST: 'livesellnow.com',
  SHOP: 'quickstart-59bc5295.myshopify.com'
}
