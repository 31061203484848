import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../services/firebase-connection";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../redux-store/slices/user-details";
import "./style.scss";
import {
  createUserOnDataBase,
  getUserFromDataBase,
  updateUserOnDataBase,
} from "../../services/user-services";
import LoadingScreen from "../../components/loader";
import { redirectToLogin, redirectToSignup } from "../../services/helpers";
import toast, { Toaster } from "react-hot-toast";
import { Box } from "@mui/material";
import ReactLoading from "react-loading";
import { doc, getDoc } from "firebase/firestore";
const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [search] = useSearchParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginLoader, setLoginLoader] = useState(false);
  const dispatch = useDispatch();

  let streamDetails = useSelector(
    (store) => store.liveStreamData?.stream_details
  );
  console.log("streamDetails", streamDetails);
  let queryParams = JSON.parse(localStorage.getItem("queryParams"));
  const { streamId, uid } = queryParams;

  let streamUrl = `?uid=${uid}&streamId=${streamId}`;

  console.log("login", "queryParams: ", queryParams);

  let newHomeUrl = `?uid=${streamDetails.stream_owner_uid}&streamId=${streamDetails.stream_uid}`;

  const onLogin = (e) => {
    console.log("onLogin");
    e.preventDefault();
    setLoginLoader(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const token = await userCredential.user.getIdToken();
        localStorage.setItem("authToken", token);
        const user = userCredential.user;
        let checkDocRes = await getDoc(doc(db, "users", user.uid));
        if (!checkDocRes.exists()) {
          user.displayName = user.displayName || "";
          await createUserOnDataBase(user);
        } else {
          const updateUserResponse = await updateUserOnDataBase(user.uid, user);
          console.log("updateUserResponse", updateUserResponse);
        }
        let dataFromDb = await getUserFromDataBase(user.uid);
        console.log("dataFromDb", dataFromDb);
        const {
          accessToken,
          email,
          displayName,
          emailVerified,
          phoneNumber,
          photoURL,
          uid,
          avatar,
          name_on_card,
          profile_picture,
        } = dataFromDb;
        localStorage.setItem(
          "userDetails",
          JSON.stringify({
            accessToken,
            email,
            avatar,
            displayName,
            emailVerified,
            phoneNumber,
            photoURL,
            uid,
            name_on_card,
            profile_picture,
          })
        );
        dispatch(setUserDetails(dataFromDb));
        setLoginLoader(false);
        navigate("/setting");
        // navigate(`/live-stream${streamUrl}`);
      })
      .catch((error) => {
        setLoginLoader(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        showToaster({
          info: errorMessage,
          id: "login_error",
          duration: 3000,
          icon: "⚠️",
        });
      });
  };

  const handleSignupButton = () => {
    navigate("/signup");
  };

  const showToaster = ({ info, id, duration, icon }) => {
    console.log(info, id, duration, icon);
    toast(
      (t) => {
        return <span className="text-black/50">{info ? info : ""}</span>;
      },
      {
        id: id ? id : "",
        duration: duration ? duration : 4000,
        icon: icon ? icon : "",
      }
    );
  };

  return (
    <div className="login_content">
      <Toaster
        toastOptions={{
          duration: 5000,
          style: {
            marginTop: "100px",
          },
        }}
      />
      <div className="login_box">
        <div className="login_box_inner">
          <img src="assets/images/logo.png" className="login_logo" alt="" />
          <p className="welcome_text_login">
            <span>Welcome to </span>Live Auction
          </p>
          <form onSubmit={(e) => onLogin(e)}>
            <div className="login_control mb_16">
              <p className="login_password_label">Email Address</p>
              <input
                // type="text"
                className="form-control login_form_control"
                placeholder="Enter your email"
                name="email"
                type="email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="login_control mb_8">
              <p className="login_password_label">Password</p>
              <input
                type="password"
                className="form-control login_form_control"
                placeholder="Enter your password"
                name="password"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <i
                className="fa fa-eye-slash password_eye_login"
                aria-hidden="true"
              ></i>
              <i
                className="fa fa-eye password_eye_login"
                aria-hidden="true"
              ></i>
            </div>
            <div className="forget_password">
              <a href="#" className="forgot_password_btn">
                FORGOT PASSWORD?
              </a>
            </div>
            <button type="submit" className="btn continue_login_btn">
              {loginLoader ? (
                <Box
                  sx={{
                    // width: "10px",
                    // marginTop: "100px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ReactLoading
                    type={"spin"}
                    color="black"
                    height={25}
                    width={25}
                  />
                </Box>
              ) : (
                "Login"
              )}
            </button>
            <p className="have_account">
              Don’t have an account?{" "}
              <button
                href={""}
                onClick={handleSignupButton}
                className="have_account_btn"
                style={{ color: "blue" }}
              >
                Sign Up
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
