import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../services/firebase-connection";
import { useSelector } from "react-redux";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import moment from "moment";
import ReactLoading from "react-loading";

const Winnings = () => {
  let userDetails = useSelector((store) => store.user.userDetails);
  const [productOrders, setProductOrders] = useState([]);
  const [productOrdersLoader, setProductOrdersLoader] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setProductOrdersLoader(true);
        if (userDetails.uid) {
          const getOrders = await getDocs(
            collection(db, "users", userDetails.uid, "product_order_placed")
          );
          if (getOrders.empty) {
            toast.error("User has no products");
          } else {
            let orders = [];
            getOrders.forEach((doc) => {
              console.log("order--->", {
                ...doc.data(),
                order_id: doc.id,
              });
              orders.push({
                ...doc.data(),
                order_id: doc.id,
              });
            });
            setProductOrders(orders);
          }
        }
        setProductOrdersLoader(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
        // Optionally, show an error notification
        toast.error("Failed to load products");
      }
    })();
  }, [userDetails]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {/* <Typography align="left" width={"95%"}>
        Product details
      </Typography> */}
      <Toaster />
      {productOrdersLoader ? (
        <Box
          sx={{
            width: "95%",
            marginTop: "100px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ReactLoading type={"spin"} color="black" height={25} width={25} />
        </Box>
      ) : (
        <Box sx={{ width: "95%", marginTop: "10px" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Purchase Date</TableCell>
                  <TableCell>Title / Product</TableCell>
                  <TableCell>Product Cost</TableCell>
                  <TableCell>Merchant ID</TableCell>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Stream ID</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productOrders.length > 0 ? (
                  productOrders.map((row, i) => {
                    let date_and_time = moment
                      .unix(row.createdAT)
                      .format("YYYY-MM-DD HH:mm:ss");
                    return (
                      <TableRow
                        key={i + 1}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {date_and_time}
                        </TableCell>
                        <TableCell>{row.product_id}</TableCell>
                        <TableCell>{row.bidAmount}</TableCell>
                        <TableCell>{row.streamOwnerUid}</TableCell>
                        <TableCell>{row.order_id}</TableCell>
                        <TableCell>{row.streamId}</TableCell>
                        <TableCell>Order {row.status}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow
                    key={"sjdfl"}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    style={{ textAlign: "center" }}
                  >
                    Empty
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </div>
  );
};

export default Winnings;
