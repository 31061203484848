export function getInitials(inputString) {
  const words = inputString.split(/\s+/);
  const initials = words.map((word) => word.charAt(0).toUpperCase());
  const result = initials.join("");
  return result;
}

export const redirectToLogin = (path, url, search) => {
  console.log("url", `/${path}?redirect=${url}${search}`);
  window.location.href = `/${path}?redirect=${url}${search}`;
  // window.location.href = `/setting`;
};

export const redirectToSignup = (path, search) => {
  window.location.href = `/${path}${search ? search : ""}`;
};
