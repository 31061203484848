import React, { useState, useEffect } from "react";
import moment from "moment";
import { getInitials } from "../../services/helpers";
import "./style.scss";
import { getAvatar } from "../../services/user-services";
const BidComponent = (props) => {
  const [isVisible, setIsVisible] = useState(true);
  let { profileURL, displayName, bidAmount, createdAT, avatar } = props.data;
  console.log({ profileURL, displayName, bidAmount, createdAT, avatar });
  useEffect(() => {
    const currentTime = moment();
    const bidTime = moment.unix(createdAT);
    const timeDifference = currentTime.diff(bidTime, "seconds");
    console.log(timeDifference);
    if (timeDifference < 2) {
      const timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, (2 - timeDifference) * 1000);
      return () => clearTimeout(timeoutId);
    } else {
      setIsVisible(false);
    }
  }, []);

  return (
    <div
      className={`bid-component ${isVisible ? "visible" : "hidden"}`}
      style={{ padding: "2px 0px" }}
    >
      <div
        className="bid_content_right_inner"
        style={{ backgroundColor: "rgba(128, 128, 128, 0.5)" }}
      >
        <div className="bid_content_profile">
          {profileURL || avatar ? (
            <img src={profileURL || getAvatar(avatar)} alt="" />
          ) : (
            <div
              style={{
                backgroundColor: "rgb(243 201 201)",
                height: "35px",
                width: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
              }}
            >
              <h4>{displayName ? getInitials(displayName) : ""}</h4>
            </div>
          )}
        </div>
        <p className="jack_ross_text">
          <span>{displayName ? displayName : "******"}</span> $
          {bidAmount ? bidAmount : 0}
        </p>
      </div>
    </div>
  );
};

export default BidComponent;
