import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { firebaseConfigure } from "../../env";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: firebaseConfigure.apiKey,
  authDomain: firebaseConfigure.authDomain,
  projectId: firebaseConfigure.projectId,
  storageBucket: firebaseConfigure.storageBucket,
  messagingSenderId: firebaseConfigure.messagingSenderId,
  appId: firebaseConfigure.appId,
  measurementId: firebaseConfigure.measurementId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const dbbase = getDatabase(app);
export default app;
