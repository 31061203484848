import { combineReducers, configureStore } from "@reduxjs/toolkit";
import counterSlice from "./slices/counter-slice";
import userDetails from "./slices/user-details";
import liveStreamData from "./slices/live-stream-data";

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  counter: counterSlice,
  user: userDetails,
  liveStreamData: liveStreamData,
},)

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
  reducer: persistedReducer, 
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store); 