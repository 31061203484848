import {
  getFirestore,
  setDoc,
  collection,
  addDoc,
  doc,
  getDoc,
  updateDoc,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import { db } from "../firebase-connection";
import avatar_1 from "../../assets/icons/avatar_1.svg";
import avatar_2 from "../../assets/icons/avatar_2.svg";
import avatar_3 from "../../assets/icons/avatar_3.svg";
import avatar_4 from "../../assets/icons/avatar_4.svg";
import avatar_5 from "../../assets/icons/avatar_5.svg";
import avatar_6 from "../../assets/icons/avatar_6.svg";
import avatar_7 from "../../assets/icons/avatar_7.svg";
import avatar_8 from "../../assets/icons/avatar_8.svg";
import avatar_9 from "../../assets/icons/avatar_9.svg";
import avatar_10 from "../../assets/icons/avatar_10.svg";
import chat_avatar from "../../assets/images/chat-avatar.png";
import chat_avatar_2 from "../../assets/images/chat-avatar-2.png";
import chat_avatar_3 from "../../assets/images/chat-avatar-3.png";
import chat_avatar_4 from "../../assets/images/chat-avatar-4.png";
import chat_avatar_5 from "../../assets/images/chat-avatar-5.png";

export const getAvatar = (id) => {
  switch (id) {
    case "avatar_1":
      return avatar_1;
    case "avatar_2":
      return avatar_2;
    case "avatar_3":
      return avatar_3;
    case "avatar_4":
      return avatar_4;
    case "avatar_5":
      return avatar_5;
    case "avatar_6":
      return avatar_6;
    case "avatar_7":
      return avatar_7;
    case "avatar_8":
      return avatar_8;
    case "avatar_9":
      return avatar_9;
    case "avatar_10":
      return avatar_10;
    default:
      return chat_avatar_5;
  }
};
export const createUserOnDataBase = async (user) => {
  try {
    const {
      accessToken,
      email,
      displayName,
      emailVerified,
      phoneNumber,
      photoURL,
      uid,
    } = user;
    const userDocRef = doc(db, "users", user.uid);
    const queryRef = query(
      collection(db, "users"),
      where("email", "==", user.email)
    );
    const querySnapshot = await getDocs(queryRef);
    if (!querySnapshot.empty) {
      let userInfo;
      querySnapshot.forEach((doc) => {
        console.log("user info", doc.data());
        userInfo = doc.data();
        userInfo.id = doc.id;
      });
      await updateDoc(doc(db, "users", userInfo.id), {
        live_streaming: false,
        live_streaming_url: "",
        live_streaming_uid: "",
        accessToken,
        avatar: `avatar_${Math.floor(Math.random() * 10) + 1}`,
        displayName,
        emailVerified,
        phoneNumber,
        photoURL,
        uid,
      });
      console.log("account got updated");
    } else {
      const res = await setDoc(userDocRef, {
        email,
        live_streaming: false,
        live_streaming_url: "",
        live_streaming_uid: "",
        accessToken,
        avatar: `avatar_${Math.floor(Math.random() * 10) + 1}`,
        displayName,
        emailVerified,
        phoneNumber,
        photoURL,
        uid,
        block: []
      });
      console.log("User data added to Firestore", res);
    }
    return "user created";
  } catch (error) {
    console.error("Error adding user data to Firestore:", error.message);
  }
};

export const updateUserOnDataBase = async (uid, data) => {
  try {
    const docRef = doc(db, "users", uid);
    console.log("data to update", data);
    await updateDoc(docRef, {
      ...data,
      displayName:
        data.first_name &&
        data.last_name &&
        data.first_name + " " + data.last_name,
    });
    console.log("Document field updated successfully");
  } catch (error) {
    console.error("Error updating document field:", error.message);
  }
};

export const getUserFromDataBase = async (uid) => {
  try {
    try {
      const docRef = doc(db, "users", uid);
      const data = await getDoc(docRef);
      if (!data.exists()) {
        return `no user for this uid is found : ${uid}`;
      }
      console.log("Document searched successfully");
      return data.data();
    } catch (error) {
      console.error("Error updating document field:", error.message);
    }
  } catch (err) {
    console.log("error encountered ---->", err);
    return "error";
  }
};
