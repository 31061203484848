import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: "",
};

export const counterSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      console.log("setUserDetails---->", state, action);
      if (!action.payload) return;
      const {
        accessToken,
        email,
        displayName,
        emailVerified,
        metadata,
        phoneNumber,
        photoURL,
        avatar,
        uid,
        name_on_card,
      } = action.payload;
      state.userDetails = {
        accessToken,
        email,
        displayName,
        emailVerified,
        metadata,
        phoneNumber,
        avatar,
        photoURL,
        uid,
        name_on_card,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserDetails } = counterSlice.actions;

export default counterSlice.reducer;
