import { useDispatch } from "react-redux";
import { db, storage } from "../firebase-connection";
import { updateProfile } from "firebase/auth";
import {
  addDoc,
  collection,
  onSnapshot,
  serverTimestamp,
  doc,
  getDoc,
  query,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  setLikeReacts,
  setLiveBidders,
  setLiveBids,
  setLiveChat,
  setLiveStreamDetail,
  setLoveReacts,
  setLiveStreamScheduleDetails,
} from "../../redux-store/slices/live-stream-data";
import moment from "moment";
import cryptoRandomString from "crypto-random-string";

import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

export const sendStreamChatMessageService = async (
  streamUserId,
  userLiveStreamId,
  userDetails,
  message
) => {
  try {
    const messageDocRef = doc(db, "live_streams", userLiveStreamId);

    let guestId = localStorage.getItem("guestId");
    if (!guestId) {
      guestId = cryptoRandomString({ length: 10, type: "alphanumeric" });
      guestId = `Guest_${guestId}`;
      localStorage.setItem("guestId", guestId);
    }
    const { uid, displayName, photoURL, avatar } = userDetails;
    await addDoc(collection(messageDocRef, "messages"), {
      text: message,
      name: displayName ? displayName : "Guest",
      avatar: avatar ? avatar : "",
      photoURL: photoURL ? photoURL : "",
      createdAt: moment().unix(),
      sentAt: moment().unix(),
      uid: uid ? uid : guestId,
      streamUserId: streamUserId,
      userLiveStreamId: userLiveStreamId,
    });
    return "message added";
  } catch (err) {
    console.log("error encountered", err);
    return "error";
  }
};

export const getLiveStreamChatMessages = async (
  dispatch,
  streamUserId,
  userLiveStreamId
) => {
  try {
    const messageDocRef = doc(db, "live_streams", userLiveStreamId);
    const messagesCollection = collection(messageDocRef, "messages");
    const unsubscribe = onSnapshot(messagesCollection, (snapshot) => {
      let messages = [];
      snapshot.docs.map((document) => {
        messages.push(document.data());
      });
      dispatch(setLiveChat(messages));
    });
    return unsubscribe;
  } catch (err) {
    console.log("error encountered", err);
    return "error";
  }
};

export const getLiveStreamBidders = async (
  dispatch,
  streamUserId,
  userLiveStreamId
) => {
  try {
    const messageDocRef = doc(
      db, "live_streams",
      userLiveStreamId
    );
    const streamLiveBiddersCollection = collection(
      messageDocRef,
      "stream_live_bidders"
    );
    const unsubscribe = onSnapshot(streamLiveBiddersCollection, (snapshot) => {
      let stream_bids = [];
      snapshot.docs.map((document) => {
        stream_bids.push(document.data());
      });
      dispatch(setLiveBidders(stream_bids));
    });
    return unsubscribe;
  } catch (err) {
    console.log("error encountered", err);
    return "error";
  }
};

export const getLiveStreamBids = async (
  dispatch,
  streamUserId,
  userLiveStreamId
) => {
  try {
    const messageDocRef = doc(
      db, "live_streams",
      userLiveStreamId
    );
    const streamLiveBids = collection(messageDocRef, "stream_bids");
    const unsubscribe = onSnapshot(streamLiveBids, (snapshot) => {
      let messages = [];
      snapshot.docs.map((document) => {
        messages.push(document.data());
      });
      dispatch(setLiveBids(messages));
    });
    return unsubscribe;
  } catch (err) {
    console.log("error encountered", err);
    return "error";
  }
};

export const getLiveStreamLikeReacts = async (
  dispatch,
  streamUserId,
  userLiveStreamId
) => {
  try {
    const messageDocRef = doc(
      db, "live_streams",
      userLiveStreamId
    );
    const streamLikeReacts = collection(messageDocRef, "stream_like_reacts");
    const unsubscribe = onSnapshot(streamLikeReacts, (snapshot) => {
      let messages = [];
      snapshot.docs.map((document) => {
        messages.push(document.data());
      });
      dispatch(setLikeReacts(messages));
    });
    return unsubscribe;
  } catch (err) {
    console.log("error encountered", err);
    return "error";
  }
};

export const getLiveStreamLoveReacts = async (
  dispatch,
  streamUserId,
  userLiveStreamId
) => {
  try {
    const messageDocRef = doc(
      db, "live_streams",
      userLiveStreamId
    );
    const streamLoveReacts = collection(messageDocRef, "stream_love_reacts");
    const unsubscribe = onSnapshot(streamLoveReacts, (snapshot) => {
      let messages = [];
      snapshot.docs.map((document) => {
        messages.push(document.data());
      });
      dispatch(setLoveReacts(messages));
    });
    return unsubscribe;
  } catch (err) {
    console.log("error encountered", err);
    return "error";
  }
};

export const getLiveStreams = async () => {
  try {
    const q = query(
      collection(db, "users"),
      where("live_streaming", "==", true)
    );
    const querySnapshot = await getDocs(q);
    const documentIds = querySnapshot.docs.map((doc) => {
      let d = doc.data();
      return {
        stream_owner_uid: doc.id,
        live_stream_uid: d.live_streaming_uid,
        live_streaming: true,
        live_streaming_url: d.live_streaming_url,
      };
    });
    console.log("Document IDs with isEnable true:", documentIds);
    return documentIds;
  } catch (err) {
    console.log("error encountered---->", err);
    return "error";
  }
};

export const getLiveStreamDetails = async ({
  stream_owner_uid,
  live_stream_uid,
}) => {
  try {
    console.log(stream_owner_uid, live_stream_uid);
    const docRef = doc(db, "live_streams", live_stream_uid);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      console.log("Document data:", data);
      return data;
    } else {
      console.log("Document does not exist");
      return null;
    }
  } catch (err) {
    console.log("error encountered getLiveStreamDetails ---->", err);
    return "error";
  }
};
export const bidNowService = async ({
  stream_owner_uid,
  live_stream_uid,
  bid_amount,
  userDetails,
  liveStreamData,
}) => {
  try {
    console.log(
      stream_owner_uid,
      live_stream_uid,
      bid_amount,
      userDetails.uid,
      liveStreamData
    );
    const docRef = doc(
      db, "live_streams",
      live_stream_uid
    );
    await addDoc(collection(docRef, "stream_bids"), {
      uid: userDetails.uid,
      displayName: userDetails.displayName,
      photoURL: userDetails.photoURL,
      avatar: userDetails.avatar,
      createdAT: moment().unix(),
      bidAmount: bid_amount,
    });
    console.log("Document field updated successfully");
  } catch (error) {
    console.error("Error updating document field:", error.message);
  }
};

export const getLiveStreamDetailServices = async (
  dispatch,
  stream_owner_uid,
  live_stream_uid
) => {
  try {
    console.log("dsbfjsk", stream_owner_uid, live_stream_uid);
    const docRef = doc(
      db, "live_streams",
      live_stream_uid
    );

    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      console.log("Updated snapshot:", snapshot.data());
      let stream_detail = snapshot.data();
      let data = {
        stream_uid: live_stream_uid,
        stream_owner_uid: stream_owner_uid,
        stream_url: stream_detail?.stream_url,
        like_reacts: stream_detail?.stream_like_reacts,
        love_reacts: stream_detail?.stream_love_reacts,
        live_bidders: stream_detail?.stream_live_bidders,
        highest_bid: stream_detail?.stream_highest_bid,
        highest_bid_user_id: stream_detail?.stream_highest_bid_user_id,
        stream_time_limit: stream_detail?.stream_time_limit,
        bids: stream_detail?.stream_bids,
        live_bidders: stream_detail?.stream_live_bidders,
        stream_status: stream_detail?.stream_status,
        stream_time_reset: stream_detail?.stream_time_reset
          ? stream_detail?.stream_time_reset
          : 0,
      };
      dispatch(setLiveStreamDetail(data));
    });

    // Return the unsubscribe function if needed
    return unsubscribe;
  } catch (err) {
    console.log("error encountered", err);
    return "error";
  }
};

export const updateLiveBidderAction = async (
  type,
  userDetails,
  streamDetails
) => {
  try {
    console.log(
      "type",
      type,
      "user details",
      userDetails,
      "stream details",
      streamDetails
    );
    const docRef = doc(
      db,
      "live_streams",
      streamDetails.stream_details.stream_uid
    );
    if (type == "watching") {
      const queryRef = query(
        collection(docRef, "stream_live_bidders"),
        where("uid", "==", userDetails.uid)
      );
      const querySnapshot = await getDocs(queryRef);
      if (querySnapshot.empty) {
        await addDoc(collection(docRef, "stream_live_bidders"), {
          uid: userDetails.uid,
          displayName: userDetails.displayName,
          photoURL: userDetails.photoURL,
          avatar: userDetails.avatar,
        });
      }
    }
    if (type == "not-watching") {
      const queryRef = query(
        collection(docRef, "stream_live_bidders"),
        where("uid", "==", userDetails.uid)
      );
      const querySnapshot = await getDocs(queryRef);
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
    }
    if (type == "like_react") {
      let bidder = streamDetails.like_reacts.filter(
        (b) => b.uid == userDetails.uid
      );
      console.log(bidder, streamDetails.like_reacts, userDetails.uid);
      if (bidder.length == 0) {
        await addDoc(collection(docRef, "stream_like_reacts"), {
          uid: userDetails.uid,
          displayName: userDetails.displayName,
          photoURL: userDetails.photoURL,
          avatar: userDetails.avatar,
        });
      }
    }
    if (type == "dislike_react") {
      const queryRef = query(
        collection(docRef, "stream_like_reacts"),
        where("uid", "==", userDetails.uid)
      );
      const querySnapshot = await getDocs(queryRef);
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
    }
    if (type == "love_react") {
      let bidder = streamDetails.love_reacts.filter(
        (b) => b.uid == userDetails.uid
      );
      console.log(bidder, streamDetails.love_reacts, userDetails.uid);
      if (bidder.length == 0) {
        await addDoc(collection(docRef, "stream_love_reacts"), {
          uid: userDetails.uid,
          displayName: userDetails.displayName,
          photoURL: userDetails.photoURL,
          avatar: userDetails.avatar,
        });
      }
    }
    if (type == "undo_love_react") {
      const queryRef = query(
        collection(docRef, "stream_love_reacts"),
        where("uid", "==", userDetails.uid)
      );
      const querySnapshot = await getDocs(queryRef);
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
    }
    if (type == "reset_timer") {
      let docRef = doc(db, 'live_streams', streamDetails.stream_details.stream_uid);
      await updateDoc(docRef, {
        stream_time_reset: streamDetails.stream_details.stream_time_reset
          ? streamDetails.stream_details.stream_time_reset + 1
          : 1,
      });
    }
  } catch (err) {
    console.log("error encountered", err);
    return "error";
  }
};

const sortByTime = (a, b) => {
  const timeA = new Date(a.date_and_time);
  const timeB = new Date(b.date_and_time);
  return timeA - timeB;
};
export const handleGetStreamScheduleInfo = async (uid, dispatch) => {
  try {
    const docRef = doc(db, "users", uid);
    const messagesCollection = collection(docRef, "live_streams");
    const unsubscribe = onSnapshot(messagesCollection, (snapshot) => {
      let messages = [];
      // let messagesfilter = [];
      snapshot.docs.map((document) => {
        let data = document.data();
        // console.log(data);
        let temp = {
          channel_uid: data.aws_channel,
          stream_uid: data.uid,
          bid_duration: data?.stream_time_limit,
          date_and_time: data?.stream_time_limit_starter,
          title: data?.stream_title,
          starting_bid: data?.stream_starting_bid,
          broadcast_url: data?.broadcast_url,
          stream_url: data?.streaming_url,
          stream_status: data?.stream_status,
          stream_winner: data?.stream_winner,
        };
        if (temp.broadcast_url && temp.stream_url) {
          // if (data.stream_time_limit_starter > moment().unix()) {
          //   messagesfilter.push(temp);
          // }
          messages.push(temp);
        }
      });
      messages.sort(sortByTime);
      dispatch(setLiveStreamScheduleDetails(messages));
    });
    return unsubscribe;
  } catch (err) {
    console.log("error--->", err);
  }
};

export async function upload(file, currentUser, setLoading) {
  setLoading(true);
  console.log("currentUser", currentUser);
  const fileRef = ref(storage, currentUser.uid + ".png");

  setLoading(true);

  const snapshot = await uploadBytes(fileRef, file);
  const photoURL = await getDownloadURL(fileRef);
  console.log("snapshot", snapshot);
  console.log("photoURL", photoURL);
  await updateProfile(currentUser, { photoURL });
  // alert("Uploaded file!");
  return photoURL;
}
