import React, { useEffect, useState, useRef } from "react";
import {
  getLiveStreamChatMessages,
  sendStreamChatMessageService,
} from "../../services/firebase-services";
import { useSelector, useDispatch } from "react-redux";
import "./style.scss";
import chat_avatar from "../../assets/images/chat-avatar.png";
import chat_avatar_2 from "../../assets/images/chat-avatar-2.png";
import chat_avatar_3 from "../../assets/images/chat-avatar-3.png";
import chat_avatar_4 from "../../assets/images/chat-avatar-4.png";
import chat_avatar_5 from "../../assets/images/chat-avatar-5.png";
import moment from "moment";
import { getAvatar } from "../../services/user-services";
import { getInitials } from "../../services/helpers";
export const StreamChatBox = ({ uid, streamId }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [liveChatMessages, setLiveStreamMessages] = useState([]);
  const userDetails = useSelector((store) => store.user.userDetails);
  const streamDetails = useSelector(
    (store) => store.liveStreamData.stream_details
  );
  const streamLiveBidders = useSelector(
    (store) => store.liveStreamData.live_bidders
  );

  let guestIdFromStore = localStorage.getItem("guestId");
  const sendMessage = async (event) => {
    event.preventDefault();
    if (message.trim() === "") {
      alert("Enter valid message");
      return;
    }
    let streamUserId = uid;
    let userLiveStreamId = streamId;
    await sendStreamChatMessageService(
      streamUserId,
      userLiveStreamId,
      userDetails,
      message
    );
    setMessage("");
  };

  useEffect(() => {
    console.log("useeffect called");
    (async () => {
      try {
        let streamUserId = uid;
        let userLiveStreamId = streamId;
        let messages = await getLiveStreamChatMessages(
          dispatch,
          streamUserId,
          userLiveStreamId
        );
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    })();
  }, []);


  const chat = useSelector((store) => store.liveStreamData.stream_chat);
  const user = useSelector((store) => store.user.userDetails);
  useEffect(() => {
    setLiveStreamMessages(chat);
  }, [chat]);

  
  const messagesEndRef = useRef(null);
  useEffect(() => {
    scrollToBottom();
    console.log("live chat messages:", liveChatMessages);
  }, [liveChatMessages]);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  return (
    <>
      <div className="inner_list right_content_body">
        <div className="right_content_body_inner">
          <div className="bid_chat_header">
            <div className="bid_chat_header_top">
              <div className="bid_chat_left">
                <p className="live_bidder_text">
                  Live Bidders
                  <span>
                    ({streamLiveBidders ? streamLiveBidders.length : 0})
                  </span>
                </p>
                <a href="#" className="view_eye">
                  <div style={{ paddingTop: "5px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="16"
                      viewBox="0 0 20 16"
                      fill="none"
                    >
                      <path
                        d="M13 8C13 9.65922 11.6592 11 10 11C8.34078 11 7 9.65922 7 8C7 6.34078 8.34078 5 10 5C11.6592 5 13 6.34078 13 8Z"
                        stroke="#89879E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        clippathstrokelinejoin="round"
                      />
                      <path
                        d="M10 15C13.2468 15 16.2729 13.2405 18.3792 10.1952C19.207 9.00242 19.207 6.99758 18.3792 5.80483C16.2729 2.75952 13.2468 1 10 1C6.75319 1 3.72713 2.75952 1.62085 5.80483C0.793051 6.99758 0.793051 9.00242 1.62085 10.1952C3.72713 13.2405 6.75319 15 10 15Z"
                        stroke="#89879E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        clippathstrokelinejoin="round"
                      />
                    </svg>
                  </div>
                </a>
              </div>
              <a href="#" className="view_all_link">
                View All
              </a>
            </div>
            <div className="bid_chat_header_bottom">
              {streamLiveBidders &&
                streamLiveBidders.length > 0 &&
                streamLiveBidders.map((s, i) => {
                  return (
                    <div key={i} className="chat_media_avatar chat_media_avatar_online">
                      {s.avatar || s.photoURL ? (
                        <img src={s.photoURL || getAvatar(s.avatar)} alt="" />
                      ) : (
                        <div
                          style={{
                            backgroundColor: "rgb(243 201 201)",
                            height: "40px",
                            width: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                          }}
                        >
                          <h4>
                            {userDetails.displayName
                              ? getInitials(userDetails.displayName)
                              : ""}
                          </h4>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="bid_chat_body">
            <div className="chat_live_heading">
              <p className="chat_live_heading_">Chat</p>
              <span>LIVE</span>
            </div>
            <div className="message_chat">
              {/* <div className="chat_body"> */}
              {liveChatMessages.length > 0 &&
                liveChatMessages.map((msg, i) => {
                  if (user.uid == msg.uid || guestIdFromStore == msg.uid) {
                    return (
                      <div className="message_chat_right" key={i}>
                        <div className="message_body_right">
                          <div className="message_text_right">{msg.text}</div>
                          <div className="message_info_right">
                            {moment.unix(msg.sentAt).format("h:mm A")}
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="message_chat_left" key={i}>
                        <div className="message_image">
                          {msg.avatar || msg.photoURL ? (
                            <img
                              className="img-circle medium-image"
                              src={msg.photoURL || getAvatar(msg.avatar)}
                              alt=""
                            />
                          ) : (
                            <div
                              style={{
                                backgroundColor: "rgb(243 201 201)",
                                height: "40px",
                                width: "40px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                              }}
                            >
                              <h4>{msg.name ? getInitials(msg.name) : ""}</h4>
                            </div>
                          )}
                        </div>
                        <div className="message_body_left">
                          <div className="message_text_left">{msg.text}</div>
                          <div className="message_info_left">
                            {moment.unix(msg.sentAt).format("h:mm A")}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              <div ref={messagesEndRef}></div>
              {/* </div> */}
            </div>
          </div>
          <div className="bid_chat_footer">
            <form className="chat_submit_group" onSubmit={sendMessage}>
              <input
                type="text"
                className="form-control chat_form_control"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
              <button type="submit" className="chat_button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="52"
                  height="52"
                  viewBox="0 0 52 52"
                  fill="none"
                >
                  <g filter="url(#filter0_d_79_151)">
                    <rect
                      x="5"
                      y="5"
                      width="42"
                      height="42"
                      rx="21"
                      fill="#44B80D"
                    />
                  </g>
                  <path
                    d="M24.4258 27.5785L26.3559 25.6508M24.4258 27.5785C23.0109 28.9943 17.0588 26.5055 18.1277 23.3579C19.1966 20.2104 30.9018 16.3669 33.268 18.7344C35.6341 21.102 31.7755 32.8514 28.6438 33.8805C25.5121 34.9096 23.0109 28.9943 24.4258 27.5785Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    clippathstrokelinejoin="round"
                  />
                  <defs>
                    <filter
                      id="filter0_d_79_151"
                      x="0"
                      y="0"
                      width="52"
                      height="52"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.266667 0 0 0 0 0.721569 0 0 0 0 0.0509804 0 0 0 0.1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_79_151"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_79_151"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </button>
              <button type="button" className="emog_button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11C21 16.5228 16.5228 21 11 21Z"
                    stroke="#89879E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    clippathstrokelinejoin="round"
                  />
                  <path
                    d="M15.4998 13.5C15.4998 13.5 13.9998 15.5 10.9998 15.5C7.99976 15.5 6.49976 13.5 6.49976 13.5"
                    stroke="#89879E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    clippathstrokelinejoin="round"
                  />
                  <path
                    d="M14.4998 7.99976C14.2237 7.99976 13.9998 7.7759 13.9998 7.49976C13.9998 7.22362 14.2237 6.99976 14.4998 6.99976C14.7759 6.99976 14.9998 7.22362 14.9998 7.49976C14.9998 7.7759 14.7759 7.99976 14.4998 7.99976Z"
                    fill="#89879E"
                    stroke="#89879E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    clippathstrokelinejoin="round"
                  />
                  <path
                    d="M7.49976 7.99976C7.22362 7.99976 6.99976 7.7759 6.99976 7.49976C6.99976 7.22362 7.22362 6.99976 7.49976 6.99976C7.7759 6.99976 7.99976 7.22362 7.99976 7.49976C7.99976 7.7759 7.7759 7.99976 7.49976 7.99976Z"
                    fill="#89879E"
                    stroke="#89879E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    clippathstrokelinejoin="round"
                  />
                </svg>
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
