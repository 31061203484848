import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stream_urls: [],
  stream_uid: "",
  stream_owner_uid: "",
  stream_details: {},
  stream_chat: [],
  bids: [],
  like_reacts: [],
  love_reacts: [],
  live_bidders: [],
  stream_schedules: [],
};

export const liveStreamDataSlice = createSlice({
  name: "liveStreamData",
  initialState,
  reducers: {
    setLiveChat: (state, action) => {
      let chat = action.payload;
      const sortedArray = chat.sort((a, b) => {
        let atemp = a.sentAt + "000";
        let btemp = b.sentAt + "000";
        return atemp - btemp;
      });
      state.stream_chat = sortedArray;
    },
    setLiveStreamDetail: (state, action) => {
      let data = action.payload;
      state.stream_details = { ...state.stream_details, ...data };
      state.stream_uid = data.stream_uid;
      state.stream_owner_uid = data.stream_owner_uid;
    },
    setLiveBids: (state, action) => {
      let data = action.payload;
      state.bids = data;
    },
    setLikeReacts: (state, action) => {
      let data = action.payload;
      state.like_reacts = data;
    },
    setLoveReacts: (state, action) => {
      let data = action.payload;
      state.love_reacts = data;
    },
    setLiveBidders: (state, action) => {
      let data = action.payload;
      state.live_bidders = data;
    },
    setLiveStreams: (state, action) => {
      let live_streams = action.payload;
      state.stream_urls = live_streams;
    },
    setLiveStreamScheduleDetails: (state, action) => {
      let streams = action.payload;
      state.stream_schedules = streams;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLiveStreamDetail,
  setLiveChat,
  setLiveBids,
  setLikeReacts,
  setLoveReacts,
  setLiveBidders,
  setLiveStreams,
  setLiveStreamScheduleDetails,
} = liveStreamDataSlice.actions;

export default liveStreamDataSlice.reducer;
